@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap");
@import "./theme.scss";

html,
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  user-select: none;
}

body {
  background-image: url("../assets/background.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

#sapiens {
  display: flex;
  flex: 1;
}

.icon {
  transition: fill 0.5s ease;

  &-inactive {
    fill: $darkGray;
  }
  &-active {
    fill: $primary;
  }
}

header {
  .logo {
    height: 44px;
    width: 129px;
  }
  .gender {
    &-button {
      opacity: 0.65;
      box-sizing: border-box;
      width: 54px;
      height: 54px;
      margin: 0 8px;
      border-radius: 50%;
      border: 2px dashed rgba($gray, 0.5); // rgba(221, 218, 220, 0.5);
      background: $white;
      transition: box-shadow 0.2s ease, border-color 0.5s ease,
        border-style 0.2s ease;

      svg {
        padding: 10px;
        border-radius: 50%;
      }

      &.active {
        opacity: 1;
        border: 5px solid $primary;
        box-shadow: 0px 16px 19px rgba($primary, 0.28);
      }

      &.inactive {
        height: 48px;
        width: 48px;
        margin: 0 11px;
      }

      :hover {
        cursor: pointer;
      }
    }
    &-title {
      position: relative;
      top: 19px;
      font-weight: 600;
      &.hidden {
        visibility: hidden;
      }
    }
  }

  .export {
    position: relative;
    box-sizing: border-box;
    margin-left: 40px;
    border-radius: 24px;
    background: $primary;

    &-title {
      width: 61px;
      color: $white;
      padding: 18px;
      border-radius: 24px;
      font-size: 14px;
      font-weight: 800;
      line-height: 8px;
    }

    &-show {
      box-sizing: border-box;
      position: absolute;
      top: 44px;
      width: 100%;
      padding: 0 9px 12px 9px;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      background: $primary;
      color: $white;
    }

    &-row {
      padding: 9px;

      &:hover {
        background: $black;
        border-radius: 4px;
        transition: background-color 0.5s ease;
      }
    }

    &-hidden {
      display: none;
    }

    :hover {
      cursor: pointer;
    }
  }
  .no-bottom-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.builder {
  padding: 40px;

  > section {
    min-width: 140px;
  }

  .toggler {
    cursor: pointer;
    width: 24px;
    border-radius: 50%;
    margin: 11px 15px;
  }

  .option,
  .style {
    &-show {
      visibility: visible;
      opacity: 1;
      transition: all 0.3s ease;
    }
    &-hidden {
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease;
    }
  }

  .row {
    .icon {
      cursor: pointer;
      border-radius: 50%;
    }

    &.active {
      color: $black; // rgba(54, 61, 97, 1);
      transition: color 0.4s ease;

      .icon {
        border: 5px solid $primary;
        padding: 10px;
        margin: 7px 24px 7px 0;
        box-shadow: 0px 16px 19px rgba($primary, 0.28); // rgba(166, 99, 99, 0.28);
        transition: box-shadow 0.2s ease, border-color 0.5s ease,
          border-style 0.2s ease, fill 0.4s ease;

        &.reverse {
          margin: 7px 0 7px 24px;
        }
      }
    }

    &.inactive {
      color: rgba($black, 0.2);
      transition: color 0.4s ease;

      .icon {
        border: 2px dashed rgba(85, 117, 147, 0.15);
        padding: 8px;
        margin: 12px 29px 12px 5px;
        transition: box-shadow 0.2s ease, border-color 0.5s ease,
          border-style 0.2s ease, fill 0.4s ease;

        &.reverse {
          margin: 12px 5px 12px 29px;
        }
      }
    }
  }
  .character {
    position: relative;
    min-width: 480px;
    min-height: 480px;

    .head {
      position: absolute;
    }
    .top {
      position: absolute;
      z-index: 2;
    }
    .bottom {
      position: absolute;
    }

    .backdrops {
      z-index: 3;
      position: absolute;
      left: calc(50% - 32px / 2 - 252px);
      top: calc(50% - 32px / 2 - 208px);

      .text {
        position: absolute;
        top: 10px;
        left: 40px;
        color: $darkGray;
      }

      &-label {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        border-top-right-radius: 24px;
        backdrop-filter: blur(4px);
        box-shadow: 0px 16px 19px rgba(166, 99, 99, 0.15);
        background: $white;

        &.show {
          opacity: 1;
          visibility: visible;
          transition: all 0.3s ease;
        }

        &.hidden {
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s ease;
        }
      }

      &-container {
        z-index: 3;
        position: absolute;
        width: 224px;

        width: 224px;
        max-width: 224px;
        height: auto;

        top: 24px;
        left: -120px;
        border-radius: 8px;
        background-color: rgba($white, 0.85);
        box-shadow: 0px 16px 19px rgba(166, 99, 99, 0.15);
        backdrop-filter: blur(4px);

        .title {
          height: 40px;
          padding: 0 16px;
          border-bottom: 1px solid rgba($gray, 0.5); //rgba(221, 218, 220, 0.5);
          color: $black;

          .icon-container {
            cursor: pointer;
          }
        }

        &.show {
          opacity: 1;
          visibility: visible;
          transition: all 0.3s ease;
        }

        &.hidden {
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s ease;
        }
      }

      &-options {
        padding: 8px;

        .option {
          width: 88px;
          height: 66px;
          margin: 8px;
          cursor: pointer;
          border-width: 4px;
          border-radius: 4px;
          border-color: transparent;
          background-color: rgba($gray, 0.25);

          &.active {
            border-color: $primary;
            background-color: $white;
          }
        }
      }
    }

    .remix {
      z-index: 3;
      position: absolute;
      left: calc(50% - 32px / 2 + 77px);
      top: calc(50% - 32px / 2 - 46px);

      .text {
        position: absolute;
        top: 10px;
        left: 40px;
        color: $darkGray;
      }

      &-label {
        cursor: pointer;
        z-index: 2;
        width: 32px;
        height: 32px;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        border-top-right-radius: 24px;
        backdrop-filter: blur(4px);
        box-shadow: 0px 16px 19px rgba(166, 99, 99, 0.15);
        background: $white;

        &.show {
          visibility: visible;
          opacity: 1;
          transition: all 0.3s ease;
        }

        &.hidden {
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease;
        }
      }

      &-container {
        z-index: 3;
        position: absolute;
        width: 152px;
        max-width: 152px;
        height: auto;
        top: 40px;
        right: 0;
        border-radius: 8px;
        background-color: rgba($white, 0.85);
        box-shadow: 0px 16px 19px rgba(166, 99, 99, 0.15);
        backdrop-filter: blur(4px);

        .title {
          height: 40px;
          padding: 0 16px;
          border-bottom: 1px solid rgba($gray, 0.5); //rgba(221, 218, 220, 0.5);
          color: $black;

          .icon-container {
            cursor: pointer;
          }
        }

        &.show {
          visibility: visible;
          opacity: 1;
          transition: all 0.3s ease;
        }

        &.hidden {
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease;
        }
      }

      &-head {
        height: 48px;
        margin-top: 12px;
        background-repeat: no-repeat;
        background-position: top;
        background-size: "cover";
      }
      &-top {
        height: 64px;
        margin-top: 12px;
        background-repeat: no-repeat;
        background-position: top;
        background-size: "cover";
      }
      &-bottom {
        height: 112px;
        margin-top: 12px;
        margin-bottom: 12px;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: "cover";
      }

      &-option {
        background-color: rgba($gray, 0.25);
        border-radius: 4px;
        border-style: solid;
        margin-left: 16px;
        margin-right: 16px;
        cursor: pointer;
        border-color: transparent;
        transition: border-color 0.3s ease;
        background-origin: border-box;
        background-position: center;
        &.active {
          border-color: $primary;
          .arrow {
            position: absolute;
            height: inherit;
            background-color: $primary;
            width: 30px;
            align-items: center;
            justify-content: center;
            &.left {
              left: -30px;
              border-radius: 8px 0 0 8px;
            }
            &.right {
              right: -30px;
              border-radius: 0 8px 8px 0;
            }
          }
        }
      }
    }
  }
}

.footer {
  > section {
    width: 180px;
  }

  &-terms {
    a {
      margin-left: 5px;
      color: $darkGray;
    }
  }
  &-purchase {
    justify-content: center;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 174px;
      height: 44px;
      border-radius: 24px;
      color: $white;
      background: $primary;
      font-weight: 800;
      font-size: 14px;
    }
  }
  &-links {
    a {
      font-weight: 600;
    }
  }
}
