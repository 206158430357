// colors
$primary: #ff3e3e;
$secondary: #1f93ff;

$gray: #dddadc;
$lightGray: #fffdfb;
$darkGray: #557593;

$black: #363d61;
$white: #ffffff;

a {
  color: $primary;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

header,
footer,
.builder {
  padding: 40px;
}

// custom classes
.flex {
  display: flex;
}
.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.center {
  align-items: center;
}
.middle {
  justify-content: center;
}
.left {
  justify-content: flex-start;
}
.right {
  justify-content: flex-end;
}
.top {
  justify-content: flex-start;
}
.bottom {
  justify-content: flex-end;
}
.wrap {
  flex-wrap: wrap;
}

.self-end {
  align-self: flex-end;
}

$spaces: "between", "evenly", "around";

@each $i in $spaces {
  .space-#{$i} {
    justify-content: space-#{$i};
  }
}
